import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import dateTimeService from '@/services/cal/dateTimeService.js';
import RichTextEditor from '@/components/core/RichTextEditor.vue';
import TaskService from '@/services/task/taskService.js';
import WarningDialog from '@/components/core/WarningDialog.vue';
import StatusDialog from '@/components/task/StatusDialog.vue';
import TaskObjects from '@/components/task/TaskObjects.vue';
import TaskAssigned from '@/components/task/TaskAssigned.vue';
import DatePicker from '@/components/task/DatePicker.vue';
import TitleDialog from '@/components/task/TitleDialog.vue';
import axios from 'axios';
export default {
  components: {
    RichTextEditor,
    WarningDialog,
    StatusDialog,
    TaskObjects,
    TaskAssigned,
    DatePicker,
    TitleDialog
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    objects: {
      addresses: [],
      addressesWithOutDocuments: []
    },
    noToolbar: {
      modules: {
        toolbar: false
      }
    },
    panel: [],
    dateTimeService,
    newComment: '',
    navigationValue: '',
    availableTitles: []
  }),
  computed: {
    ...mapState({
      task: state => state.task.taskDetailed,
      user: state => state.user.user
    }),
    currentDate: function () {
      return new Date();
    },
    showTakeOverButton() {
      if (this.task.status.done) return false;
      if (this.task.assignedWorkGroups.length == 0 && this.task.assignedUsers.length == 1 && this.task.assignedUsers[0].userId == this.user.userId) return false;
      return true;
    }
  },
  mounted() {
    this.loadTask();
  },
  methods: {
    takeOverTask() {
      axios.patch(`/v1/task/${this.task.lfdnr}/assignees`, {
        assignedUsers: [this.user],
        assignedWorkGroups: []
      }).then(response => {
        var users = response.data.assignedUsers;
        var groups = response.data.assignedWorkGroups;
        this.saveAssignees(users, groups);
        this.$toast.success('Aufgabe wurde übernommen');
      }).catch(error => {
        console.log(error);
      });
    },
    async loadTask() {
      this.$store.dispatch('loadTask', this.lfdnr).then(() => {
        this.getObjects();
      });
      await TaskService.getTitles().then(availableTitles => {
        this.availableTitles = availableTitles;
      });
    },
    async getObjects() {
      this.objects = {
        addresses: [],
        addressesWithOutDocuments: []
      };
      let addresses = [];
      let documents = [];
      this.task.objects.forEach(element => {
        if (element.type === 'ADDRESS') {
          addresses.push(element);
        } else if (element.type === 'DOCUMENT') {
          documents.push(element);
        }
      });
      addresses.forEach(address => {
        let tempObject = {
          lfdnr: address.lfdnr,
          taskId: address.taskId,
          wfCid: address.wfCid,
          type: address.type,
          displayName: address.displayName,
          parentWfCid: address.parentWfCid,
          documents: []
        };
        documents.forEach((document, index) => {
          if (document.parentWfCid === address.wfCid) {
            tempObject.documents.push(document);
            documents.splice(index, 1);
          }
        });
        if (tempObject.documents.length != 0) {
          this.objects.addresses.push(tempObject);
        } else {
          this.objects.addressesWithOutDocuments.push(tempObject);
        }
      });

      // sorts comments by lfdnr
      this.task.comments.sort((a, b) => a.lfdnr - b.lfdnr);
      this.states = await TaskService.getStates();
    },
    saveObjects(addressObjects, documentObjects) {
      documentObjects = documentObjects.map(object => {
        return {
          wfCid: object.wfCid,
          taskId: this.task.lfdnr
        };
      });
      axios.post('v1/task-resources/base/addresses', addressObjects.map(object => object.additionalData.lfdnr)).then(response => {
        addressObjects = JSON.parse(JSON.stringify(response.data));
        addressObjects = response.data.map(object => {
          return {
            wfCid: object.wfCid,
            taskId: this.task.lfdnr
          };
        });
        console.log(documentObjects);
        console.log(addressObjects);
        const patchableObjects = documentObjects.concat(addressObjects);
        axios.patch(`v1/task/${this.task.lfdnr}/objects`, patchableObjects).then(response => {
          this.task.objects = response.data;
          this.getObjects();
          this.$toast.success('Änderungen gespeichert');
        });
      });
    },
    async descriptionEdited(description) {
      try {
        await TaskService.editDescription(this.task.lfdnr, description);
        this.$toast.success('Beschreibung wurde verändert');
      } catch (e) {
        console.error(e);
      }
    },
    openDatePicker() {
      if (this.task.dueDate != null) {
        this.$refs.datePicker.open(dateTimeService.getDateStringFromDateTimeString(this.task.dueDate));
      } else {
        this.$refs.datePicker.open(dateTimeService.getDateStringFromDateTimeString(this.currentDate.toISOString()));
      }
    },
    openTaskObjects() {
      this.$refs.objects.open(this.task.objects);
    },
    openTaskAssigned() {
      this.$refs.assigned.open(this.task.assignedUsers, this.task.assignedWorkGroups);
    },
    openTitleDialog() {
      var actions = [{
        icon: 'mdi-content-save',
        text: 'Speichern',
        action: 'save'
      }, {
        icon: 'mdi-close',
        text: 'Abbrechen',
        action: 'cancel'
      }];
      this.$refs.changeTitleDialog.open(actions);
    },
    async changeTitle(action, title) {
      if (action == 'save') {
        try {
          await TaskService.editTitle(this.task.lfdnr, title);
          this.task.title = title;
          this.$toast.success('Titel wurde verändert');
        } catch (e) {
          console.error(e);
        }
      }
    },
    async changeDueDate(date) {
      try {
        await TaskService.editDueDate(this.task.lfdnr, date);
        this.task.dueDate = date;
        this.$toast.success('Fälligkeitsdatum wurde verändert');
      } catch (e) {
        console.error(e);
      }
    },
    saveAssignees(assignedUsers, assignedWorkGroups) {
      this.task.assignedUsers = assignedUsers;
      this.task.assignedWorkGroups = assignedWorkGroups;
    },
    openStatusPicker() {
      var actions = [{
        icon: 'mdi-content-save',
        text: 'Speichern',
        action: 'save'
      }, {
        icon: 'mdi-close',
        text: 'Abbrechen',
        action: 'cancel'
      }];
      this.$refs.changeStatusDialog.open('Bitte wählen Sie einen Status aus', actions);
    },
    async changeStatus(action, selectedStatus) {
      if (action == 'save' && selectedStatus.key != undefined) {
        try {
          await TaskService.editStatus(this.task.lfdnr, selectedStatus);
          this.task.status = selectedStatus;
          this.$toast.success('Status wurde geändert');
        } catch (e) {
          console.error(e);
        }
      }
    },
    async setNotifications(notificationsActive) {
      try {
        await TaskService.editNotificationState(this.task.lfdnr, notificationsActive);
        this.task.notificationsActive = notificationsActive;
      } catch (e) {
        console.error(e);
      }
    },
    async addComment() {
      var text = this.$refs.richTextNewCommentEditor.editor.getHTML();
      if (text == '<p></p>') {
        var actions = [{
          icon: 'mdi-plus',
          text: 'Verfassen',
          action: 'create'
        }, {
          icon: 'mdi-close',
          text: 'Abbrechen',
          action: 'cancel'
        }];
        this.$refs.warningAddComment.open('Dieser Kommentar ist leer. Wollen Sie ihn trotzdem verfassen?', actions);
        return;
      }
      this.addCommentForce();
    },
    async addCommentForce() {
      var lfdnr = this.lfdnr;
      var text = this.$refs.richTextNewCommentEditor.editor.getHTML();
      await axios.put('/v1/task-comments', {
        taskId: lfdnr,
        text: text
      }).then(response => {
        this.task.comments.push(response.data);
      });
      this.$refs.richTextNewCommentEditor.editor.commands.setContent('');
      this.$toast.success('Kommentar wurde erstellt');
    },
    async commentEdited(comment) {
      try {
        await TaskService.editComment(this.task.lfdnr, comment.id, comment.text);
        this.$toast.success('Kommentar wurde bearbeitet');
      } catch (e) {
        console.error(e);
      }
    },
    tryDeleteComment(commentId) {
      var actions = [{
        icon: 'mdi-delete',
        text: 'Löschen',
        action: 'delete'
      }, {
        icon: 'mdi-close',
        text: 'Abbrechen',
        action: 'cancel'
      }];
      this.deleteCommentId = commentId;
      this.$refs.warningDeleteComment.open('Wollen Sie diesen Kommentar löschen?', actions);
    },
    async deleteComment(action) {
      if (action == 'delete' && this.deleteCommentId != -1) {
        try {
          await TaskService.deleteComment(this.deleteCommentId);
          this.task.comments.splice(this.task.comments.findIndex(item => item.lfdnr === this.deleteCommentId), 1);
          this.deleteCommentId = -1;
          this.$toast.success('Kommentar wurde gelöscht');
        } catch (e) {
          console.error(e);
        }
      }
    },
    tryDeleteTask() {
      var actions = [{
        icon: 'mdi-delete',
        text: 'Löschen',
        action: 'delete'
      }, {
        icon: 'mdi-close',
        text: 'Abbrechen',
        action: 'cancel'
      }];
      this.$refs.warningDeleteTask.open('Wollen Sie die Aufgabe löschen?', actions);
    },
    async deleteTask(action) {
      if (action == 'delete') {
        try {
          await TaskService.deleteTask(this.task.lfdnr);
          this.$toast.success('Aufgabe wurde gelöscht');
          this.$router.push({
            name: 'Task'
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
};