import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "1000px",
      "persistent": ""
    },
    model: {
      value: _vm.taskHistory,
      callback: function ($$v) {
        _vm.taskHistory = $$v;
      },
      expression: "taskHistory"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.taskHistory = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Aufgabenverlauf")])], 1)], 1), _c(VCardText, [_c(VTimeline, _vm._l(_vm.history, function (entry) {
    return _c(VTimelineItem, {
      key: entry.lfdnr
    }, [_c(VCard, [_c(VCardTitle, [_vm._v(_vm._s(entry.title) + " ")]), _c(VCardSubtitle, [_vm._v(_vm._s(entry.userId) + " am " + _vm._s(_vm.dateTimeService.getGermanDateFormat(entry.date)))]), _c(VCardText, [_vm._v(_vm._s(entry.description))])], 1)], 1);
  }), 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    staticClass: "primary",
    on: {
      "click": function ($event) {
        _vm.taskHistory = false;
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };