import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VLayout, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c(VContainer, {
    staticClass: "px-2 py-0"
  }, [_c(VContainer, [_c('h1', {
    staticClass: "d-flex justify-start align-center"
  }, [_c(VChip, {
    attrs: {
      "color": "primary mr-2"
    }
  }, [_vm._v("# " + _vm._s(_vm.task.lfdnr))]), _vm._v(" " + _vm._s(_vm.task.title) + " "), _c(VBtn, {
    staticClass: "ms-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openTitleDialog
    }
  }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1)], 1), _c('p', {
    staticClass: "text--secondary"
  }, [_vm._v("erstellt von " + _vm._s(_vm.task.creationUser) + ", am " + _vm._s(_vm.dateTimeService.getShortGermanDate(_vm.task.creationDate)))]), _c(VDivider, {
    staticClass: "my-3"
  }), _c(VContainer, [_c('RichTextEditor', {
    ref: "richTextDescriptionEditor",
    attrs: {
      "text": _vm.task.description,
      "placeholder": "Hier Text eingeben",
      "editable": "",
      "optionalEditing": "",
      "editingCancel": ""
    },
    on: {
      "saved": _vm.descriptionEdited
    }
  })], 1)], 1), _c(VContainer, {
    staticClass: "mt-5"
  }, [_c('h2', {
    staticClass: "mt-3"
  }, [_vm._v("Kommentare")]), _c(VDivider, {
    staticClass: "my-3"
  }), _c(VList, [_vm._l(_vm.task.comments, function (com) {
    return _c(VListItem, {
      key: com.lfdnr
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-comment")])], 1), _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "d-flex align-center"
    }, [_vm._v(_vm._s(com.userId) + " am " + _vm._s(_vm.dateTimeService.getGermanDateFormat(com.creationDate))), _c(VBtn, {
      staticClass: "ml-3",
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.tryDeleteComment(com.lfdnr);
        }
      }
    }, [com.userId === _vm.user.userId ? _c(VIcon, {
      attrs: {
        "small": "",
        "color": "error"
      }
    }, [_vm._v("mdi-delete")]) : _vm._e()], 1)], 1), _c('RichTextEditor', {
      attrs: {
        "text": com.text,
        "saveId": com.lfdnr,
        "placeholder": "Hier Text eingeben",
        "editable": "",
        "optionalEditing": "",
        "editingCancel": ""
      },
      on: {
        "saved": _vm.commentEdited
      }
    })], 1)], 1);
  }), _c(VListItem, [_c(VListItemIcon, [_c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-comment-edit-outline")])], 1), _c(VListItemContent, [_c('RichTextEditor', {
    ref: "richTextNewCommentEditor",
    attrs: {
      "text": _vm.newComment,
      "placeholder": "Hier Text eingeben",
      "editable": ""
    }
  }), _c(VRow, {
    staticClass: "mt-1 me-0"
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": _vm.addComment
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")]), _vm._v("Kommentar verfassen")], 1)], 1)], 1)], 1)], 2)], 1)], 1), _c('WarningDialog', {
    ref: "warningAddComment",
    on: {
      "warningActionPerformed": _vm.addCommentForce
    }
  }), _c('WarningDialog', {
    ref: "warningDeleteTask",
    on: {
      "warningActionPerformed": _vm.deleteTask
    }
  }), _c('WarningDialog', {
    ref: "warningDeleteComment",
    on: {
      "warningActionPerformed": _vm.deleteComment
    }
  }), _c('TaskHistory', {
    ref: "history"
  }), _c('TaskObjects', {
    ref: "objects",
    on: {
      "save": _vm.saveObjects
    }
  }), _c('TaskAssigned', {
    ref: "assigned",
    attrs: {
      "taskId": _vm.lfdnr
    },
    on: {
      "saved": _vm.saveAssignees
    }
  }), _c('DatePicker', {
    ref: "datePicker",
    on: {
      "datePicked": _vm.changeDueDate
    }
  }), _c('TitleDialog', {
    ref: "changeTitleDialog",
    attrs: {
      "title": _vm.task.title,
      "availableTitles": _vm.availableTitles
    },
    on: {
      "actionPerformed": _vm.changeTitle
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "3"
    }
  }, [_c(VNavigationDrawer, {
    staticClass: "overflow-y-auto pa-5 pt-15",
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "permanent": "",
      "fixed": "",
      "clipped": "",
      "right": "",
      "width": "fit-content"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c(VListItem, {
          staticClass: "mt-8",
          on: {
            "click": _vm.openDatePicker
          }
        }, [_c(VListItemIcon, [_c(VBtn, {
          attrs: {
            "icon": "",
            "ripple": false
          }
        }, [_vm.task.dueDate != null ? _c(VIcon, {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-clock")]) : _c(VIcon, [_vm._v("mdi-clock-outline")])], 1)], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v("Fälligkeitsdatum")]), _vm.task.dueDate != null ? _c(VListItemSubtitle, [_vm.dateTimeService.isBeforeToday(_vm.task.dueDate) ? _c(VIcon, {
          staticClass: "mb-1 mr-1",
          attrs: {
            "small": "",
            "color": "warning"
          }
        }, [_vm._v("mdi-alert")]) : _vm._e(), _vm._v(_vm._s(_vm.dateTimeService.getShortGermanDate(_vm.task.dueDate)))], 1) : _c(VListItemSubtitle, [_vm._v("-")])], 1)], 1), _c(VListItem, [_c(VListItemIcon, [_c(VBtn, {
          attrs: {
            "icon": ""
          }
        }, [!_vm.isTaskDone ? _c(VIcon, [_vm._v(" mdi-checkbox-blank-circle-outline ")]) : _c(VIcon, {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-check-circle-outline")])], 1)], 1), _c(VListItemContent, [_c(VAutocomplete, {
          attrs: {
            "outlined": "",
            "dense": "",
            "label": "Status",
            "hide-details": "",
            "items": _vm.states,
            "item-text": "description",
            "item-color": "color",
            "return-object": ""
          },
          on: {
            "change": _vm.statusChanged
          },
          model: {
            value: _vm.task.status,
            callback: function ($$v) {
              _vm.$set(_vm.task, "status", $$v);
            },
            expression: "task.status"
          }
        })], 1)], 1), _c(VListItem, {
          attrs: {
            "disabled": ""
          }
        }, [_c(VListItemIcon, [!_vm.task.notificationsActive ? _c(VBtn, {
          attrs: {
            "disabled": "",
            "icon": "",
            "ripple": false
          },
          on: {
            "click": function ($event) {
              return _vm.setNotifications(true);
            }
          }
        }, [_c(VIcon, [_vm._v(" mdi-bell-outline ")])], 1) : _vm.task.notificationsActive ? _c(VBtn, {
          attrs: {
            "icon": "",
            "ripple": false
          },
          on: {
            "click": function ($event) {
              return _vm.setNotifications(false);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" mdi-bell ")])], 1) : _vm._e()], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v("Benachrichtigungen ")]), _c(VListItemSubtitle, [_vm._v(_vm._s(_vm.notificationLabel))])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-center"
        }, [_c(VBtn, {
          staticClass: "mr-2",
          attrs: {
            "color": "primary",
            "elevation": "0",
            "disabled": _vm.task.history == null
          },
          on: {
            "click": _vm.openTaskHistory
          }
        }, [_c('span', [_vm._v("Verlauf")]), _c(VIcon, [_vm._v("mdi-history")])], 1), _c(VBtn, {
          attrs: {
            "id": "deleteButton",
            "color": "error",
            "elevation": "0"
          },
          on: {
            "click": _vm.tryDeleteTask
          }
        }, [_c('span', [_vm._v("Löschen")]), _c(VIcon, {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c(VDivider, {
    staticClass: "my-3"
  }), _c('h3', {
    staticClass: "mx-2 text-h6"
  }, [_vm._v(" Verlinkte Elemente "), _c(VBtn, {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.openTaskObjects
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1), _c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [_vm._l(_vm.objects.addressesWithOutDocuments, function (address) {
    return _c(VListItem, {
      key: address.wfCid
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(address.displayName))])], 1)], 1);
  }), _vm._l(_vm.objects.addresses, function (address) {
    return _c(VListGroup, {
      key: address.wfCid,
      attrs: {
        "prepend-icon": "mdi-account"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(address.displayName))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(address.documents, function (document) {
      return _c(VListItem, {
        key: document.wfcid,
        attrs: {
          "dense": ""
        }
      }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-file-document")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(document.displayName))])], 1)], 1);
    }), 1);
  })], 2), _c(VDivider, {
    staticClass: "my-3"
  }), _c('h3', {
    staticClass: "mx-2 text-h6"
  }, [_vm._v(" Bearbeiter "), _c(VBtn, {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.openTaskAssigned
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1), _c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [_vm._l(_vm.task.assignedUsers, function (usr) {
    return _c(VListItem, {
      key: usr.userId
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
  }), _vm._l(_vm.task.assignedWorkGroups, function (grp) {
    return _c(VListGroup, {
      key: grp.code,
      attrs: {
        "prepend-icon": "mdi-account-group"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(grp.name))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(grp.users, function (usr) {
      return _c(VListItem, {
        key: usr.userId
      }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
    }), 1);
  })], 2), _vm.showTakeOverButton ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "elevation": "0",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.takeOverTask();
      }
    }
  }, [_vm._v("Aufgabe Übernehmen")]) : _vm._e()], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };