import dateTimeService from '@/services/cal/dateTimeService.js';
export default {
  data: () => ({
    taskHistory: false,
    dateTimeService,
    history: []
  }),
  components: {},
  methods: {
    open(history) {
      this.history = history;
      this.taskHistory = true;
    }
  }
};